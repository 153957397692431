.account-popup-content {
    padding: 0 15px;
    .ant-divider.ant-divider-horizontal {
        margin: 8px 0 ;
    }
}

.account-popup {
    border-radius: 5px;
}

.ant-popover-inner {
    border-radius: 10px !important;
}


.ant-carousel {
    .slick-prev, .slick-next {
        color: lightgray !important;
        font-size: 30px !important;
    }

    .slick-prev { 
        left: -15px !important;       
    }

    .slick-next {
        right: -5px !important;
    }
}

.user-legend {
    .menu-dropdown {
        margin-top: 8px;
        font-size: 10px;
    }
}

@media all and (min-device-width: 768px) {
    .account-popup {
        padding-top: 30px !important;
    }
}

@media all and (max-device-width: 576px) {
    .landing-card {
        height: 200px !important;
    }
}



