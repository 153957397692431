
.app-watermark{
  align-self: center;
  bottom: 0;
  /* right: 0; */
  padding: 2px;
}
#root{
  overflow-x: hidden;
  height: 100%;
}
.app-watermark > p{
  margin:0;
  color: rgba(14, 14, 14, 0.308);
}
/* width */
::-webkit-scrollbar {
          width: 5px;
  }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(161, 161, 161, 0.781); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* @supports(padding: max(0px)) {
  #root {
      padding-left: max(12px, env(safe-area-inset-left));
      padding-right: max(12px, env(safe-area-inset-right));
  }
} */

@media only screen and (max-width: 600px) {
.app-watermark{
  align-self: center;
  position: initial;
  }
  .dashboard-footer{
          width:100%;
          position: relative !important;
  }
  ::-webkit-scrollbar {
          width: 0px;
        }
}


/*Animation Keyframes*/
@-moz-keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* Firefox */
@-webkit-keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* Webkit */
@-ms-keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* IE */
@keyframes blink {0%{opacity:1;} 50%{opacity:0.5;} 100%{opacity:1;}} /* Opera */


.mt-16 {
  margin-top: 16px;
}

@media all and (max-device-width: 992px) {
  .max-width-1080 {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .powered-by-logo-text{
    display:none;
  }
}

@media all and (max-device-width: 768px) {
  .ant-carousel {
    max-width: 768px !important;
  }
}

@media all and (max-device-width: 576px) {
  .ant-carousel {
    max-width: 576px !important;
  }
}

@media all and (min-device-width: 992px) {
  .ant-drawer-content-wrapper {
    width: 600px !important;
  }

  .max-width-1080 {
    max-width: 1080px !important;
    margin: auto;
  }

  .mt-16 {
    margin-top: 16px;
  }
}

body {
    font-family: SF Pro Display,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

.ant-btn-primary {
    background-color: rgb(0, 40, 87);
}

.ant-btn.ant-btn-default.ant-btn-block:hover {
  border-color: rgb(18, 6, 57);
  color: rgb(0, 40, 87);
}
.ant-btn.ant-btn-primary.ant-btn-block {
  border-color: rgb(18, 6, 57);
  background-color:rgb(0, 40, 87);
}

.ant-btn.ant-btn-primary[disabled], 
.ant-btn.ant-btn-primary[disabled]:active, 
.ant-btn.ant-btn-primary[disabled]:focus, 
.ant-btn.ant-btn-primary[disabled]:hover {
    color: rgba(0,0,0,.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}
.ant-dropdown-menu-title-content{
  color:gray
}
.ant-select-item:hover{
  background-color: #F3FAFD;
}
.ant-dropdown-menu-item:hover{
  background-color: lightcyan ;
}

.ant-drawer-body {
  padding: 20px !important;
}

.ant-table-thead>tr>th{
  font-weight: bold;
  background: #F3FAFD;
}

.ant-table-thead>tr>th>h3 {
  font-weight: bold;
  background:#F3FAFD;
}

.pb-54 {
  padding-bottom: 54px;
}

.fix-width {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -150px;
  background: #F3FAFD;
}

.push {
  height: 150px;
}